import React, { useEffect, useRef, useState } from "react";

import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/pages/stock-stats/common/stock_mainscreen@2x.png";
import PG_SS_RATING_REVIEW_CONTENT from "../components/stock-stats/RatingReview/index.content";
import { isSavedUserInfo } from "../helpers";
import { PAGES } from "../helpers/constants";
import HomeIntro from "../components/stock-stats/HomeIntro";
import ChromeRating from "../components/stock-stats/ChromeRating";
import PartnersBrand from "../components/stock-stats/PartnersBrand";
import Pricing from "../components/stock-stats/Pricing";
import StandardFormBlockContainer from "../containers/common/forms/StandardFormBlockContainer";
import ProInstall from "../components/stock-stats/ProInstall";
import Spy from "../components/stock-stats/Spy";
import RatingReview from "../components/stock-stats/RatingReview";
import "../components/stock-stats/index.scss";

const PgSS = () => {
  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const pricingRef = useRef(null);
  const { postData } = PG_SS_RATING_REVIEW_CONTENT;

  useEffect(() => {
    setIsSecondStateCollectEmail(isSavedUserInfo());
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <Layout tariffsElement={pricingRef} customClass="PgSS">
      <Seo
        title="Spy Amazon Stocks - Inventory Management | AMZScout"
        description="Freeware. Spy on competitors sales without leaving the Amazon product page. Plan sales accordingly."
        page="stock-stats"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
        isLoading={isLoading}
      />
      <ChromeRating />
      <PartnersBrand />
      <RatingReview
        isMobile={isMobile}
        excellentText="Excellent"
        posts={postData}
      />
      <Spy />
      <Pricing
        pricingRef={pricingRef}
        sectionTitle="Stock Stats is now part of the Amazon Sellers Bundle by AMZScout!"
      />
      <ProInstall />
      <StandardFormBlockContainer
        pageFormState={isSecondStateCollectEmail}
        updatePageFormState={setIsSecondStateCollectEmail}
        buttonTextFirstState="Try Stock Stats For Free!"
        buttonTextSecondState="Try Stock Stats For Free!"
        page={PAGES.STOCK_STATS}
        customClass={
          isSecondStateCollectEmail
            ? "PgSS-standardFormPageBlock-secondState"
            : "PgSS-standardFormPageBlock-lastForm"
        }
        title={
          "Get all of the data about competitors' inventories and sales with Stock Stats FREE TRIAL!"
        }
      />
    </Layout>
  );
};

export default PgSS;
