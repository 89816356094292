import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import PROINSTALL_CONTENT from "../../common/ProInstall/index.content";
import "./index.scss";

const ProInstall = () => {
  const { pic1, pic2, pic3 } = PROINSTALL_CONTENT;
  const url =
    "https://chrome.google.com/webstore/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea?utm_source=site&utm_medium=amzscout&utm_campaign=stock_stats_lp";

  return (
    <section className="PgSS-PI">
      <div className="container">
        <h2 className="PgSS-PI__title">
          Do you need more information about Amazon products and competition?
        </h2>
        <p className="PgSS-PI__subtitle">
          Install Stock Stats on steroids - AMZScout PRO Extension in 3 clicks!
          Get a detailed overview of the niche and its potential profitability
        </p>
        <div className="PgSS-PI__list PgSS-PI-list">
          <div className="PgSS-PI-list__item">
            <picture>
              <source
                type="image/webp"
                media="(min-width: 1366px)"
                srcSet={`${pic1.webp} 1x, ${pic1.webp2x} 2x`}
              />
              <source
                media="(min-width: 1366px)"
                srcSet={`${pic1.pic} 1x, ${pic1.pic2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px) and (max-width: 1365px)"
                srcSet={`${pic1.webpTab} 1x, ${pic1.webpTab2x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 1365px)"
                srcSet={`${pic1.picTab} 1x, ${pic1.picTab2x} 2x`}
              />
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${pic1.webpMob} 1x, ${pic1.webpMob2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${pic1.picMob} 1x, ${pic1.picMob2x} 2x`}
              />
              <img
                src={pic1.pic}
                alt=""
                loading="lazy"
                className="PgSS-PI-list__img"
              />
            </picture>
            <p className="PgSS-PI-list__num">01.</p>
            <p className="PgSS-PI-list__text PgSS-PI-list-text">
              Go to the{" "}
              <TrackedLink
                category="Index"
                action="CTA"
                target
                class="PgSS-PI-list-text__link"
                label="PRO Extension for Chrome"
                path={url}
              >
                PRO Extension
              </TrackedLink>{" "}
              page in the Chrome Web Store
            </p>
            <TrackedLink
              category="Index"
              action="CTA"
              target
              class="PgSS-PI-list__btn"
              label="PRO Extension for Chrome"
              path={url}
            >
              Click here
            </TrackedLink>
          </div>
          <div className="PgSS-PI-list__item">
            <picture>
              <source
                type="image/webp"
                media="(min-width: 1366px)"
                srcSet={`${pic2.webp} 1x, ${pic2.webp2x} 2x`}
              />
              <source
                media="(min-width: 1366px)"
                srcSet={`${pic2.pic} 1x, ${pic2.pic2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px) and (max-width: 1365px)"
                srcSet={`${pic2.webpTab} 1x, ${pic2.webpTab2x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 1365px)"
                srcSet={`${pic2.picTab} 1x, ${pic2.picTab2x} 2x`}
              />
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${pic2.webpMob} 1x, ${pic2.webpMob2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${pic2.picMob} 1x, ${pic2.picMob2x} 2x`}
              />
              <img
                src={pic2.pic}
                alt=""
                loading="lazy"
                className="PgSS-PI-list__img"
              />
            </picture>
            <p className="PgSS-PI-list__num">02.</p>
            <p className="PgSS-PI-list__text">
              Click the “Add” button and wait 5 seconds
            </p>
          </div>
          <div className="PgSS-PI-list__item">
            <picture>
              <source
                type="image/webp"
                media="(min-width: 1366px)"
                srcSet={`${pic3.webp} 1x, ${pic3.webp2x} 2x`}
              />
              <source
                media="(min-width: 1366px)"
                srcSet={`${pic3.pic} 1x, ${pic3.pic2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 992px) and (max-width: 1365px)"
                srcSet={`${pic3.webpXl} 1x, ${pic3.webpXl2x} 2x`}
              />
              <source
                media="(min-width: 992px) and (max-width: 1365px)"
                srcSet={`${pic3.picXl} 1x, ${pic3.picXl2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px) and (max-width: 991px)"
                srcSet={`${pic3.webpTab} 1x, ${pic3.webpTab2x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 991px)"
                srcSet={`${pic3.picTab} 1x, ${pic3.picTab2x} 2x`}
              />
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${pic3.webpMob} 1x, ${pic3.webpMob2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${pic3.picMob} 1x, ${pic3.picMob2x} 2x`}
              />
              <img
                src={pic3.pic}
                alt=""
                loading="lazy"
                className="PgSS-PI-list__img"
              />
            </picture>
            <p className="PgSS-PI-list__num">03.</p>
            <p className="PgSS-PI-list__text">
              AMZScout PRO Extension is downloaded to your Chrome or Edge
              browser - browse products on the Amazon site and get detailed
              analytics right on the search page
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProInstall;
