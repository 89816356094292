import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

const FastImage = ({
  defaultName,
  imageData,
  imageClassName,
  imageAltName,
  isAdaptive,
  isWithRatio,
  isFullBreaks,
  wrapImageClassName,
}) => {
  const getImageFromQuery = (name) => {
    const ratio = getRatioName();
    const fileName = isWithRatio
      ? `${name}${!name.includes("@") && ratio ? "@" : ""}${ratio}`
      : name;
    const filter = imageData.allFile.edges.filter(
      (e) => e.node.name === fileName
    );
    if (filter.length > 0) {
      return filter[0].node.childImageSharp.gatsbyImageData;
    } else {
      throw new Error(`Undefined image ${fileName}`);
    }
  };

  const detectPixelRatio = () => {
    if (typeof window !== "undefined") {
      return window.devicePixelRatio;
    } else {
      return 1;
    }
  };

  const getRatioName = () => {
    if (detectPixelRatio() > 1) {
      return "2x";
    } else {
      return "";
    }
  };
  const getFullImage = () =>
    withArtDirection(getImage(getImageFromQuery(defaultName)), [
      {
        media: "(max-width: 767px)",
        image: getImage(getImageFromQuery(`${defaultName}@mob`)),
      },
      {
        media: "(min-width: 768px) and (max-width: 991px)",
        image: getImage(getImageFromQuery(`${defaultName}@mid`)),
      },
      {
        media: "(min-width: 992px) and (max-width: 1199px)",
        image: getImage(getImageFromQuery(`${defaultName}@smallDesk`)),
      },
      {
        media: "(min-width: 1200px) and (max-width: 1365px)",
        image: getImage(getImageFromQuery(`${defaultName}@midDesk`)),
      },
      {
        media: "(min-width: 1366px)",
        image: getImage(getImageFromQuery(`${defaultName}`)),
      },
    ]);

  const getCurrentImage = () =>
    withArtDirection(getImage(getImageFromQuery(defaultName)), [
      {
        media: "(max-width: 767px)",
        image: getImage(getImageFromQuery(`${defaultName}@mob`)),
      },
      {
        media: "(min-width: 768px) and (max-width: 1199px)",
        image: getImage(getImageFromQuery(`${defaultName}@mid`)),
      },
    ]);

  return (
    <GatsbyImage
      image={
        isAdaptive
          ? isFullBreaks
            ? getFullImage()
            : getCurrentImage()
          : getImage(getImageFromQuery(defaultName))
      }
      imgClassName={imageClassName}
      className={wrapImageClassName}
      alt={imageAltName}
    />
  );
};

FastImage.propTypes = {
  imageData: PropTypes.object.isRequired,
  imageClassName: PropTypes.string,
  imageAltName: PropTypes.string,
  isAdaptive: PropTypes.bool,
  isWithRatio: PropTypes.bool,
  defaultName: PropTypes.string.isRequired,
};

export default FastImage;
