import React from "react";
import PropTypes from "prop-types";
import EmailCollectContainer from "./EmailCollectContainer";
import StandardFormIntroBlock from "../../../components/common/Forms/StandardFormIntroBlock";
import StandardFormPageBlock from "../../../components/common/Forms/StandardFormPageBlock";

const StandardFormBlockContainer = (props) => {
  const {
    buttonTextFirstState,
    buttonTextSecondState,
    customClass,
    isIntro,
    redirectUrlSecondStateButton,
    onSuccess,
    operation,
    page,
    pageFormState,
    placeholder,
    redirect,
    redirectUrl,
    title,
    type,
    updatePageFormState,
    isLoading,
  } = props;
  return (
    <EmailCollectContainer
      page={page}
      redirect={redirect}
      pageAuthState={pageFormState}
      updatePageAuthState={updatePageFormState}
      onSuccess={onSuccess}
      operation={operation}
      customType={type}
      customRedirectUrl={redirectUrl}
    >
      {isIntro ? (
        <StandardFormIntroBlock
          title={title}
          customClass={customClass}
          redirectUrlSecondStateButton={redirectUrlSecondStateButton}
          buttonTextFirstState={buttonTextFirstState}
          buttonTextSecondState={buttonTextSecondState}
          placeholder={placeholder}
          isLoading={isLoading}
        />
      ) : (
        <StandardFormPageBlock
          title={title}
          buttonTextFirstState={buttonTextFirstState}
          buttonTextSecondState={buttonTextSecondState}
          customClass={customClass}
          placeholder={placeholder}
          isLoading={isLoading}
        />
      )}
    </EmailCollectContainer>
  );
};

StandardFormBlockContainer.propTypes = {
  onSuccess: PropTypes.func,
  page: PropTypes.string.isRequired,
  pageFormState: PropTypes.bool,
  placeholder: PropTypes.string,
  redirect: PropTypes.bool,
  title: PropTypes.string,
  updatePageFormState: PropTypes.func,
};

export default StandardFormBlockContainer;
